<template>
  <div class="row mt-4">
    <div class="col-md-12">
      <h1>Confirm your email address.</h1>
    </div>
    <div class="col-md-4 mt-4">
      <div class="form-group">
        <label for="eamil">Enter the email address associated with your username</label>
        <h3>{{ emailAddress }}</h3>
        <input
          v-model="form.email"
          class="form-control mt-3 emailInput"
          placeholder="Email address"
          type="text"
          v-on:keyup.enter="validateEmail"
        />
        <small v-if="errorMessage" 
        class="text-danger">{{ errorMessage }} </small>
      </div>
    </div>
    <div class="col-md-12 mt-4">
      <div class="form-group">
        <button style="margin-right: 10px" class="btn btn-outline-secondary continue-btn" @click.prevent="cancelProcess">Cancel</button>
        <button class="btn btn-primary continue-btn" @click.prevent="validateEmail">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const PasswordRecoveryModule = createNamespacedHelpers('passwordRecovery')

export default {
  name: "ValidateEmailAddress",
  props: ['emailAddress'],
  data() {
    return {
      form: {
        email: null,
        errorMessage: null,
      },
    };
  },
  computed: {
    ...PasswordRecoveryModule.mapGetters(['selectRecoveryUser', 'selectEmailError']),

    errorMessage(){
      return this.selectEmailError || this.form.errorMessage
    }
  },
  methods: {
    ...PasswordRecoveryModule.mapActions(['setCurrentStep']),

    validateEmail() {
      if(this.isValidEmailAddress(this.form.email) !== null) {
        this.form.errorMessage = null
        this.$emit('validateEmail', this.form.email);
      }
      else {
        this.form.errorMessage = `
        Please provide a valid email address
        `
      }
    },
    cancelProcess() {
      this.setCurrentStep('validate-username')
    },
    isValidEmailAddress(address){
      let isValid = false;
      const  pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/;
      isValid = (address && address.length) && address.match(pattern)
      return isValid;
    }
  },
};
</script>

<style scoped lang="scss">
.emailInput {
  height: 55px;
  border-radius: 10px;
}
a {
  text-decoration: none;
}
.continue-btn {
  width: 120px;
}
</style>
