<template>
  <div class="row mt-4">
    <div class="col-md-12">
      <h1>Having trouble signing in?</h1>
    </div>
    <div class="col-md-4 mt-4">
      <div class="form-group">
        <label for="username">Enter your username to get started.</label>
        <input
          v-model="form.username"
          class="form-control mt-3 usernameInput"
          placeholder="Username"
          type="text"
          v-on:keyup.enter="findUsername"
        />
        <small v-if="selectRecoveryUser === null" 
        class="text-danger">This username is not valid or not supported. </small>
      </div>
    </div>
    <div class="col-md-12 mt-4">
      <p>
        If you forgot your username, you can
        <a href="/account-lookup">look it up.</a>
      </p>
    </div>
    <div class="col-md-4 mt-4">
      <div class="form-group">
        <button class="btn btn-primary continue-btn" @click.prevent="findUsername">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const PasswordRecoveryModule = createNamespacedHelpers('passwordRecovery')

export default {
  name: "ValidateUsername",
  data() {
    return {
      form: {
        username: null,
      },
    };
  },
  computed: {
    ...PasswordRecoveryModule.mapGetters(['selectRecoveryUser']),

  },
  methods: {
    findUsername() {
      this.$emit('findUsername', this.form.username);
    }
  },
};
</script>

<style scoped lang="scss">
.usernameInput {
  height: 55px;
  border-radius: 10px;
}
a {
  text-decoration: none;
}
.continue-btn {
  width: 120px;
}
</style>
