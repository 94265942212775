<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-12 text-center">
        <h1>Success!</h1>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4 text-center mt-4">
        <div class="form-group">
          <p for="username"
            >You've successfully changed your password. Click button below to
            login with your new password.</p>
        </div>
        <div class="form-group mt-5">
          <button class="btn btn-primary continue-btn" @click.prevent="redirectToLogin">Sign In</button>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SuccessPasswordReset",
  data(){
    return {
      timeoutId: null,
    }
  },
  methods: {
    startConfetti(){
      this.$confetti.start();
      
      this.timeoutId = setTimeout(() => {
        this.$confetti.stop();
      }, 3000)
    },
    redirectToLogin(){
      clearTimeout(this.timeoutId);
      this.$confetti.stop();
      this.$emit('redirectToLogin')
    }
  },
  mounted() {
    this.startConfetti()
  }
};
</script>
