<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="container">
        <ValidateUsername
        v-if="step === 'validate-username'"
        @findUsername="findUsername"/>

        <ValidateEmailAddress
        :emailAddress="hashedEmail"
        v-if="step === 'validate-email'"
        @validateEmail="validateEmail"/>

        <EmailConfirmationCode
        :emailAddress="selectRecoveryUser.email"
        @validateConfirmationCode="validateConfirmationCode"
        v-if="step ==='validate-code'"/>

        <ResetPassword 
        v-if="step ==='reset-password'"
        @resetPassword="resetPassword"/>

        <SuccessPasswordReset
        @redirectToLogin="redirectToLogin"
        v-if="step ==='success-password-reset'"/>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonToolbar, IonHeader } from '@ionic/vue'
import { createNamespacedHelpers } from 'vuex'
import ResetPassword from './ResetPassword.vue'
import ValidateUsername from './ValidateUsername.vue'
import ValidateEmailAddress from './ValidateEmailAddress.vue'
import EmailConfirmationCode from './EmailConfirmationCode.vue'
import SuccessPasswordReset from './SuccessPasswordReset.vue'

const LoaderModule = createNamespacedHelpers('loaders')
const AuthenticationModule = createNamespacedHelpers('authentication')
const PasswordRecoveryModule = createNamespacedHelpers('passwordRecovery')

export default {
  name: "PasswordRecoveryBase",
  components: {
    IonPage, 
    IonContent, 
    IonToolbar, 
    IonHeader,
    ResetPassword,
    ValidateUsername,
    ValidateEmailAddress,
    EmailConfirmationCode,
    SuccessPasswordReset,
  },
  data(){
    return {
      form: {
        username: null,
      }
    }
  },
  computed: {
    ...AuthenticationModule.mapGetters(['selectHttpResponse']),
    ...PasswordRecoveryModule.mapGetters(['selectRecoveryUser', 'selectCurrentStep']),

    step(){
      return this.selectCurrentStep
    },
    hashedEmail(){
      const value = this.selectRecoveryUser.email
      let email = [...value]
      const atIndex = email.indexOf('@')

      return email.map((part, index ) => {
        if(index > 1 && index < atIndex){
          return '•'
        }
        else {
          return part
        }
      }).join('')
    }
  },
  methods: {
    ...LoaderModule.mapActions(['addToLoaders', 'removeFromLoaders']),
    ...AuthenticationModule.mapActions([
    'sendVerificationCode', 
    'verifyVerificationCode', 
    'sendResetPasswordRequest']),
    ...PasswordRecoveryModule.mapActions([
      'setCurrentStep',
      'findRecoveryUsername',  
      'setEmailErrorMessage']),

    redirectToLogin(){
      this.$router.push({ name: 'Login'})
    },
    async findUsername(username){
      // add to status loaders
      this.addToLoaders('validate-username')
      await this.findRecoveryUsername(username)
      
      if(this.selectRecoveryUser){
        this.setCurrentStep('validate-email')
      }

      // remove from status loaders
      this.removeFromLoaders('validate-username')
    },
    async validateEmail(email){
      if(email === this.selectRecoveryUser.email){
        const payload = {
          to: this.selectRecoveryUser.email,
          username: this.selectRecoveryUser.username
        }
        // add to status loaders
        this.addToLoaders('validate-email')
        await this.sendVerificationCode(payload)
        this.setCurrentStep('validate-code')
        // remove from status loaders
        this.removeFromLoaders('validate-email')
      }
      else {
        const message = `This email address is incorrect. 
        Please enter a email that you use to 
        receive verification codes.`

        this.setEmailErrorMessage(message)
      }
    },
    async resetPassword(password) {
      // add to status loaders
      this.addToLoaders('reset-password')
      await this.sendResetPasswordRequest({
        email: this.selectRecoveryUser.email,
        username: this.selectRecoveryUser.username,
        password: password,
        identifier: this.selectHttpResponse.data.identifier
      })
      // remove from status loaders
      this.removeFromLoaders('reset-password')

      const response = this.selectHttpResponse
      if(response && response.data.success){
        this.setCurrentStep('success-password-reset')
      }
    },
    async validateConfirmationCode(code){
      // add to status loaders
      this.addToLoaders('validate-code')
      await this.verifyVerificationCode({
        to: this.selectRecoveryUser.email,
        code: code,
      })
      // remove from status loaders
      this.removeFromLoaders('validate-code')

      const response = this.selectHttpResponse
      if(response && response.data.success){
        this.setCurrentStep('reset-password')
      }
    },
  }
};
</script>

<style scoped lang="scss">
  .usernameInput {
    height: 55px;
    border-radius: 10px;
  }
  a {
    text-decoration: none;
  }
  .continue-btn{
    width: 120px;
  }
</style>
