<template>
  <div class="row mt-4">
    <div class="col-md-12">
      <h1>Email confirmation code.</h1>
    </div>
    <div class="col-md-4 mt-4">
      <div class="form-group">
        <label for="confirmationCode">Please enter the confirmation code that was sent to: </label>
        <p><strong>{{ emailAddress }}</strong></p>
        <input
          v-model="form.confirmationCode"
          class="form-control mt-3 emailInput"
          placeholder="Confirmation code"
          type="text"
          v-on:keyup.enter="validateConfirmationCode"
        />
        <small v-if="errorMessage" 
        class="text-danger">{{ errorMessage }} </small>
      </div>
    </div>
    <div class="col-md-12 mt-4">
      <div class="form-group">
        <button style="margin-right: 10px" class="btn btn-outline-secondary continue-btn" @click.prevent="cancelProcess">Cancel</button>
        <button class="btn btn-primary continue-btn" @click.prevent="validateConfirmationCode">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const AuthenticationModule = createNamespacedHelpers('authentication')
const PasswordRecoveryModule = createNamespacedHelpers('passwordRecovery')

export default {
  name: "EmailConfirmationCode",
  props: ['emailAddress'],
  data() {
    return {
      form: {
        confirmationCode: null,
      },
    };
  },
  computed: {
    ...AuthenticationModule.mapGetters(['selectHttpResponse']),
    ...PasswordRecoveryModule.mapGetters(['selectRecoveryUser', 'selectEmailError']),

    errorMessage(){
      return this.selectHttpResponse && this.selectHttpResponse.data.success === false ? 
      'Invalid verification code entered, please try again' : null
    }
  },
  methods: {
    ...PasswordRecoveryModule.mapActions(['setCurrentStep']),

    cancelProcess() {
      this.setCurrentStep('validate-username')
    },
    validateConfirmationCode() {
      this.$emit('validateConfirmationCode', this.form.confirmationCode)
    },
  },
};
</script>

<style scoped lang="scss">
  .emailInput {
    height: 55px;
    border-radius: 10px;
  }

  .continue-btn {
    width: 120px;
  }
</style>
