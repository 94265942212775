<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-12 text-center">
        <h1>Enter a new password</h1>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4 text-center mt-4">
        <div class="form-group">
          <input
            v-model="form.newPassword"
            class="form-control mt-3 form-input"
            placeholder="New password"
            type="text"
            v-on:keyup.enter="resetPassword"
          />
        </div>
        <div class="form-group">
          <input
            v-model="form.confirmPassword"
            class="form-control mt-3 form-input"
            placeholder="Confirm password"
            type="text"
            v-on:keyup.enter="resetPassword"
          />
        </div>
        
        <ol v-if="errorMessages.length">
          <li v-for="error in errorMessages" :key="error">
            <small  class="text-danger">
              {{ error }}
            </small>
          </li>
        </ol>
        <div class="form-group mt-4">
          <button
            class="btn btn-primary continue-btn"
            @click.prevent="resetPassword"
          >
            Reset password
          </button>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ResetPassword",
  data() {
    return {
      form: {
        errorMessages: [],
        newPassword: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    errorMessages(){
      return this.form.errorMessages
    }
  },
  methods: {
    resetPassword() {
      if(this.validateNewPassword()){
        this.form.errorMessages = []
        this.$emit('resetPassword', this.form.newPassword)
      }
    },
    validateNewPassword() {
      this.form.errorMessages = []
      const regex = /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/

      if(this.form.confirmPassword !== this.form.newPassword){
        this.form.errorMessages.push('The new password and the confirmation password does not match.')
      }

      if(!regex.test(this.form.newPassword)){
        this.form.errorMessages.push(`Password must contain at least 8 characters, one upper case letter,
         one lower case letter and one symbol or special character.`)
      }

      return !this.form.errorMessages.length ? true : false
    }
  },
};
</script>

<style scoped lang="scss">
.form-input {
  height: 55px;
  border-radius: 10px;
}
</style>
